import React, { useState, useEffect } from "react";
import { FaLock } from "react-icons/fa";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { useAddress, useContract } from "@thirdweb-dev/react";
import { useContractWrite } from "@thirdweb-dev/react";
import { ToastContainer, toast } from "react-toastify";
import { useTokenBalance } from "@thirdweb-dev/react";
import "react-toastify/dist/ReactToastify.css";
import { FadeLoader } from "react-spinners";
import { ethers } from "ethers";
import { contractAbi, contractAddress } from "../contract";
const Section2 = () => {
  const [firstInputValue, setFirstInputValue] = useState(0);
  const [secondInputValue, setSecondValueInput] = useState(0);
  const [approveValue, setApproveValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refAddress, setRefAddress] = useState("");

  const handleFirstInput = (e) => {
    setFirstInputValue(e.target.value);
    setSecondValueInput(e.target.value * 1000);
  };

  const handleApproveField = (e) => {
    setApproveValue(e.target.value);
  };

  const address = useAddress();
  const { contract } = useContract(
    "0xDa97Aff87067f34d28A1A27C13946da4634209AF"
  );

  const { contract: USDTContract } = useContract(
    "0x55d398326f99059fF775485246999027B3197955"
  );

  const { data: walletBal, isLoading: walletBalLoading } = useTokenBalance(
    USDTContract,
    address
  );


  const { mutateAsync: approve, isLoading: isApproveLoading } =
    useContractWrite(USDTContract, "approve");

  const approveTokens = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      let spender = "0xDa97Aff87067f34d28A1A27C13946da4634209AF"; //contract address
      let approveAmount = ethers.utils.parseEther(approveValue);
      const data = await approve({ args: [spender, approveAmount] });
      toast.success("Successfully approved tokens!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);
    } catch (err) {
      toast.error("Approve Failed !", {
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);

      console.error("contract call failure", err);
    } finally {
      setIsLoading(false);
    }
  };

  const { mutateAsync: buyTokens, isLoading: isBuyTokensLoading } =
  useContractWrite(contract, "buyTokens");

  const firstInputValueString = firstInputValue.toString();
  // const buyToken = async () => {
  //   setIsLoading(true);
  //   try {
  //     let tierplan = ethers.utils.parseEther(firstInputValueString);
  //     try {
  //       const provider = new ethers.providers.Web3Provider(window.ethereum);
  //       const signer = provider.getSigner();
  //       const contract = new ethers.Contract(
  //         contractAddress,
  //         contractAbi,
  //         signer
  //       );
  //       const token = await contract.buyTokens(tierplan, refAddress);
  //       const receipt = await token.wait();
  //       if (receipt.status === 1) {
  //         toast.success("You have Successfully Purchased TDF Tokens", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       toast.error("Failed", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     }
  //     setIsLoading(false);
  //   } catch (err) {
  //     toast.error("You can not buy more than $1000 in one transaction", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     console.error("contract call failure", err);
  //     setIsLoading(false);
  //   }
  // };

  const buyToken = async () => {
    setIsLoading(true)
    try {
      let usdtAmt = ethers.utils.parseEther(firstInputValueString);
       await buyTokens({ args: [usdtAmt, refAddress] });
      toast.success("You have Successfully Purchased TDF Tokens", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error(
        "Something went Wrong",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setIsLoading(false)
      console.error("contract call failure", err);

    } finally {
     setIsLoading(false)
    }
  };

  const handleRefeField = (e) => {
    setRefAddress(e.target.value);
  };

  useEffect(() => {
    const extractAddressFromUrl = () => {
      const url = window.location.href;
      const parts = url.split("/");
      const address = parts[parts.length - 1];
      setRefAddress(address);
    };
    extractAddressFromUrl();
  }, []);

  const refHai = `${window.location.origin}/${address}`;
  const handleCopyReferralLink = () => {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = refHai;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
    toast.success("Wallet Address copied to clipboard!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };


  return (
    <div className="max-w-[1000px] mx-auto my-10">
      {isLoading && <FadeLoader className="loader_new" color="#36d7b7" />}
      <ToastContainer />
      <div className="text-white my-3 max-w-[600px] mx-auto">
        <div className="md:flex gap-3 justify-center md:justify-between mx-4 md:mx-0 bg-gradient-to-b from-[#253f7d] to-[#0c1529]  p-4 rounded-md ">
          <h3 className="text-xs mt-3">
            {window.location.origin}/
            {`${address?.slice(0, 4)}...${address?.slice(-3)}`}
          </h3>
          <button
            onClick={handleCopyReferralLink}
            className="bg-blue-400  md:m-0 flex justify-center gap-2 md:w-[157px] my-2 py-2 text-center rounded-md px-2"
          >
            Copy Referral Link
          </button>
        </div>
      </div>
      <div
        className={`${
          isLoading ? "opacity_new" : ""
        } md:flex mx-4  justify-center gap-5`}
      >
        <div className="w-full max-w-[600px] bg-gradient-to-b from-[#253f7d] to-[#0c1529] text-white mt-5 md:mt-0  p-5 rounded-md">
          <p className="mb-3 text-center">
            Available USDT Balance : {walletBal?.displayValue} USDT
          </p>
          <div className="flex gap-2">
            <input
              onChange={handleApproveField}
              placeholder="Enter amount to approve"
              className="bg-[#253f7d] focus:outline-none w-[70%] focus:border-none   px-3 text-white h-12 rounded-lg my-2 border-none"
              type="number"
            />
            <button
              onClick={approveTokens}
              className="bg-blue-400 flex justify-center w-[30%] gap-2  my-2 py-3 text-center rounded-md "
            >
              Approve
            </button>
          </div>
          <div className="relative">
            <div className="absolute flex gap-3 w-6 h-6 top-[30px] right-[60px]">
              <img src="/1.png" /> <p className="">USDT</p>
            </div>
            <input
              onChange={handleFirstInput}
              placeholder="Enter amount"
              className="bg-[#253f7d] focus:outline-none focus:border-none   px-3 text-white w-full h-12 rounded-lg mt-4 border-none"
              type="number"
            />
            {firstInputValue < 10 && (
              <p className="text-xs text-red-500 mt-1 ml-1">
                Please Enter more than or equal to 10
              </p>
            )}
          </div>
          <div className="relative">
            <input
              value={refAddress}
              placeholder="Enter Referral Address"
              className="bg-[#253f7d] focus:outline-none focus:border-none px-3 text-white w-full h-12 rounded-lg mt-4 border-none"
              type="text"
            />
          </div>
          <CgArrowsExchangeAltV className="mx-auto mt-3 bg-[#245ae4] w-5 h-5 rounded-full" />
          <div className="relative">
            <label className="absolute top-[28px] left-[16px]">
              You will get |{" "}
            </label>
            <div className="absolute flex gap-3 w-6 h-6 top-[30px] right-[60px]">
              <img className="w-full" src="/neww.png" /> <p className="">TDF</p>
            </div>
            <input
              value={secondInputValue}
              className="bg-[#253f7d] w-full h-12 rounded-lg mt-4 border-none focus:outline-none focus:border-none   pl-28"
              type="number"
            />
          </div>
          <button
            disabled={firstInputValue < 10}
            onClick={buyToken}
            className="bg-blue-400 flex justify-center gap-2 w-full my-3 py-3 text-center rounded-md "
          >
            Click to purchase <FaLock className="mt-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Section2;
