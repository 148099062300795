import logo from "./logo.svg";
import "./App.css";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Nav from "./components/Nav";

function App() {
  return (
    <div className="">
      <Nav/>
      <Section2 />
      {/* <Section3 /> */}
    </div>
  );
}

export default App;
