import React from "react";
import {
  ConnectWallet,
  useSDK,
  useTokenBalance,
  useContract,
  useAddress,
  useContractRead,
  useContractWrite,
} from "@thirdweb-dev/react";
const Nav = () => {
  return (
    <div className="max-w-[1000px] mx-auto mt-10">
    <div className="flex mx-auto px-4 justify-between max-w-[830px]">
      <div>
        <img className="max-w-[120px]" src="/logo.png" />
      </div>
      <div>
        <ConnectWallet />
      </div>
    </div>
</div>
  );
};

export default Nav;
